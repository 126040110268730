import image1 from "../../images/Galerie/galerie1.jpg";
import image2 from "../../images/Galerie/galerie2.jpg";
import image3 from "../../images/Galerie/galerie3.jpg";
import image4 from "../../images/Galerie/galerie4.jpg";
import image5 from "../../images/Galerie/galerie5.jpg";
import image6 from "../../images/Galerie/galerie6.jpg";


export const images = [
    {
        id: 1,
        url: image1,
    },
    {
        id: 2,
        url: image2,
    },
        {
        id: 3,
        url: image3,
    },
    {
        id: 4,
        url: image4,
    },
    {
        id: 5,
        url: image5,
    },
    {
        id: 6,
        url: image6,
    },
  ]
  


export default  {
    images,
}